import '../styles/globals.css';
import type { AppProps } from 'next/app';
import '@livekit/components-styles';
import '@livekit/components-styles/prefabs';
import { DefaultSeo } from 'next-seo';

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <DefaultSeo
        title="Chatter - Dev App"
        titleTemplate="%s"
        defaultTitle="Chatter Dev"
        description="Chatter"
        openGraph={{
          url: 'https://meet.chattersocial.io',
          images: [
            {
              url: 'https://avatars.githubusercontent.com/u/147637533?s=200&v=4',
              width: 100,
              height: 100,
              type: 'image/png',
            },
          ],
          site_name: 'Chatter Dev',
        }}
        additionalMetaTags={[
          {
            property: 'theme-color',
            content: '#070707',
          },
        ]}
        additionalLinkTags={[
          {
            rel: 'icon',
            href: '/favicon.ico',
          },
        ]}
      />
      <Component {...pageProps} />
    </>
  );
}

export default MyApp;
